/*
|--------------------
|      HEADER
|--------------------
*/

/*
|
| Header
|---------
*/
.link-menu {
    color: $very-dark-grey;
    font-size: 16px;
    font-weight: 400;
}

#header {
    width: 100%;
    height: $headerHeight;
    position: fixed;
    z-index: 200;
    display: flex;
    align-items: center;

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: $white;
        z-index: 1;
    }

    .header-container {
        position: relative;
        @extend .container;
        display: flex;
        align-items: center;
        justify-content: space-between;
        z-index: 10;

        .item-logo {
            svg {
                path,
                polygon {
                    fill: $black;
                }
            }
        }

        .item-nav {
            display: none;

            .item-menu {
                display: flex;

                li {
                    &:not(:last-child) {
                        margin-right: 32px;
                    }

                    .item-link {
                        @extend .link-menu;
                    }

                    &:last-child {
                        .item-link {
                            font-weight: 700;
                            color: $blue;
                        }
                    }
                }
            }

            @media screen and (min-width: 1200px) {
                display: flex;
                align-items: center;

                .item-menu {
                    margin-right: 25px;
                }
            }
        }
    }

    /*
    -------------------------------- 
    Burger
    --------------------------------
    */
    .burger {
        position: relative;
        cursor: pointer;
        pointer-events: initial;
        padding: 12px 0 12px 12px;
        display: block;
       

        div {
            height: 1px; 
            width: 30px;
            background-color: $very-dark-grey;
            display: block;
            position: relative;
            margin-bottom: 3px;
            transition: transform 0.64s $easeOutExpo, opacity 0.24s $easeOutExpo, background-color 0.24s ease;
        }

        @media screen and (min-width: 1200px) {
            pointer-events: none;
            display: none;
        }
    }
}

/*
|
| Submenu
|-------
*/
.header-sub {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    display: none;
    margin-top: $headerHeight !important;
    transition: opacity 0.2s ease;
    pointer-events: none;

    .item-container {
        display: none;

        &.active {
            display: block;
        }
    }

    &.active {
        pointer-events: initial;
        opacity: 1;
    }

    .child-nav {
        div {
            a {
                height: 80px;
                display: flex;
                align-items: center;
                border: 1px solid $white;
                transition: all 0.4s ease;

                &:hover {
                    background: $white;
                    color: $blue;

                    .icon-sector {
                        svg {
                            g,
                            path,
                            polygon,
                            rect {
                                stroke: $blue;
                            }
                        }
                    }
                    .svg-white {
                        &.svg-stroke {
                            svg {
                                path,
                                g,
                                polyline {
                                    stroke: $blue;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @include media-breakpoint-up(lg) {
        display: block;
    }
}

/*
|
| Menu
|-------
*/
#mobile-menu {
    position: fixed;
    z-index: 120;
    top: 0;
    left: 0;
    display: block;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-color: $white;
    overflow: auto;
    opacity: 0;
    visibility: hidden;

    .item-container {
        @extend .container;

        ul {
            & > li {
                &:not(:last-child) {
                    margin-bottom: 12px;
                }

                a {
                    font-size: 20px;
                    -webkit-font-smoothing: antialiased;
                }
            }
        }
    }
}

/*
-------------------------------- 
Body is Menu
--------------------------------
*/
.is-menu {
    overflow: hidden;
    
    .header-sub {
        display: none;
    }

    header {

        .burger {
            div {
                &:nth-child(2) {
                    opacity: 0;
                }

                &:first-child {
                    transform: translate3d(0, 4px, 0) rotate3d(0, 0, 1, -45deg);
                }
                &:last-child {
                    transform: translate3d(0, -4px, 0) rotate3d(0, 0, 1, 45deg);
                }
            }
        }
    }

    #mobile-menu {
        opacity: 1;
        visibility: visible;
        pointer-events: auto;
    }
}
