/*
|-----------------------
| 	  Gravity Form
|-----------------------
|
*/

/*
|
| Reset form elements
|-------------------------
*/
.gfield {
    position: relative;
}

.gfield_required {
    &:before {
        content: "*";
        position: absolute;
        top: 0;
        margin-left: 4px;
        color: $blue;
    }
    span {
        display: none;
    }
}

input:not([type="checkbox"]),
textarea,
select {
    -webkit-appearance: none;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid $blue;
    width: 100%;
    font-size: 16px;
    margin-bottom: 16px;
    background-color: transparent;

    &:focus {
        outline: none;
    }
}

select {
    width: 100%;
}

label {
    font-size: 14px;
    color: $blue;

    &:not(.gfield_consent_label) {
        // font-weight: 600;
        // text-transform: uppercase;
    }
}

select {
    background-image: url("../img/icon/bottom.svg");
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: calc(100% - 10px) center;
}

/*
|
| Contact form 
|---------------
*/
.materialize-form {
    ul {
        li {
            position: relative;
            width: 100%;
            margin-bottom: 20px;
        }
    }

    .gform_footer {
        margin-top: 60px;
        // text-align: right;
    }

    input[type="submit"] {
        position: relative;
        background-color: $blue;
        color: $white;
        width: auto;
        padding: 16px 40px;
        transition: all 0.3s ease-out;
        cursor: pointer;
        border: none;
        font-size: 16px;
        border-radius: 0px;

        &:hover {
            color: $white;
            background: $blue;
        }
    }

    select {
        color: $blue;
    }

    .gform_confirmation_message {
        color: $blue;
    }
}

.validation_error {
    font-size: 12px;
    position: absolute;
    bottom: 0;
    margin-bottom: 30px;
    color: #bb0b0b !important;
}

#gform_ajax_spinner_1 {
    position: absolute;
    bottom: 0;
    left: 0;
    display: inline-block;
    width: 50px;
    height: 50px;
}

#gform_ajax_spinner_2 {
    position: absolute;
    top: 0;
    margin-top: 35px;
    margin-left: 10px;
    display: inline-block;
    width: 50px;
    height: 50px;
}

.nl-form {
    .validation_error {
        display: none !important;
        visibility: hidden !important;
        opacity: 0 !important;
    }

    .gform_confirmation_message_2 {
        max-width: fit-content;
    }

    .validation_message {
        position: absolute;
        font-weight: 400;
        bottom: 0;
        left: 0;
        margin-bottom: -30px;
        color: #bb0b0b;
        font-size: 12px;
    }
}

.contact-form {
    .validation_message {
        position: absolute;
        font-weight: 400;
        top: 0;
        right: 0;
        color: #bb0b0b;
        font-size: 12px;
    }
}

.gfield_required {
    color: transparent !important;
}

.validation_message {
    border: none !important;
    background: transparent !important;
    margin-top: 0px !important;
    text-align: right;
    padding: 0px 0px !important;
}

#page-news-single {
    .materialize-form {
        .gfield_label {
            color: $blue;
        }

        .gfield_radio {
            display: flex;
            justify-content: space-between;

            .gchoice {
                position: relative;
                width: 48%;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 24px 0;
                cursor: pointer;

                label {
                    position: relative;
                    z-index: 10;
                    transition: all .24s ease;
                }

                &:hover{

                    label{
                        color: $white;   
                    }
                }

                &.is-active{
                    label {
                       color: $white !important;
                    }
                }
            }

            @include media-breakpoint-down(md) {
                justify-content: start;
                flex-direction: column;

                .gchoice {
                    width: 100%;
                    margin-bottom: 16px;
                }
            }
        }

        input[type="radio"] {
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: $white;
            border: 1px solid $blue;
            transition: all 0.4s ease;

            &:checked {
                background-color: $blue;
            }

            &:hover {
                background-color: $blue;
            }
        }
    }
}
