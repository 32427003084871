/*
|--------------------
|       sample
|--------------------
*/

#key-section {

  .key-container {
    height: 100%;

    border: 1px solid $blue;

    h2, h3, h4{
      color: $blue;
    }

    cms{
      color: $grey;
    }
  }

  &.bg-blue{
    h2, h3, h4{
      color: $white;
    }
    .key-container {
      border: 1px solid $white;
    }
  }

}

#page-sample {
  /*
    |
    | Video Team
    |------------------
    */
  #video-section {

    .video-player {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -30px;
      margin-top: -20px;
      background-image: url("../img/icon/arrow-bg-blue.svg");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;
      height: 80px;
      width: 60px;
      z-index: 10;
      display: flex;
      align-items: center;
      justify-content: center;
      display: none;

      svg {
        margin-left: 10px;
      }
    }

    .video-mask{
      height: 80vh;

      iframe{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  /*
    |
    | Section Team
    |------------------
    */
  #team-section {
    .member-container {
      position: relative;
      height: 380px;

      .team-img {
        transform: rotate(-360deg);
        position: relative;
        height: 80%;

        .bg-container, .bg-border{
          clip-path: polygon(38% 0, 100% 14%, 77% 88%, 7% 100%, 1% 16%);
        }
      }
    }
  }
}

.plus-container {
  position: relative;
  height: 20px;
  width: 20px;

  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -1px;
    margin-left: -10px;
    height: 2px;
    width: 20px;
    background: $blue;
  }

  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -10px;
    margin-left: -1px;
    height: 20px;
    width: 2px;
    background: $blue;
  }
}

#partner-section {
  .logo-container {
    position: relative;
    z-index: 10;
    height: auto;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    background: $white;

    img {
      width: 80%;
      height: auto;
      filter: grayscale(1);
      opacity: 0.4;
    }
  }
}

#blocs-section {
  .bloc-container {
    &.big {
      .content-container {
        min-height: 335px;
      }
    }
    .content-container {
      min-height: 270px;
      height: 100%;

      background-image: url("../img/utils/bg-arg.svg");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
    }
  }
}
