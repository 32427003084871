/*
|--------------------
|    FONTS IMPORTS
|--------------------
|
| 100 - Extra Light or Ultra Light
| 200 - Light or Thin
| 300 - Book or Demi
| 400 - Normal or Regular
| 500 - Medium
| 600 - Semibold, Demibold
| 700 - Bold
| 800 - Black, Extra Bold or Heavy
| 900 - Extra Black, Fat, Poster or Ultra Black
|
*/

@font-face {
    font-family: 'pretendard';
    src: url('../fonts/pretendard/pretendard-light.eot');
    src: url('../fonts/pretendard/pretendard-light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/pretendard/pretendard-light.woff2') format('woff2'),
        url('../fonts/pretendard/pretendard-light.woff') format('woff'),
        url('../fonts/pretendard/pretendard-light.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family: 'pretendard';
    src: url('../fonts/pretendard/pretendard-regular.eot');
    src: url('../fonts/pretendard/pretendard-regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/pretendard/pretendard-regular.woff2') format('woff2'),
        url('../fonts/pretendard/pretendard-regular.woff') format('woff'),
        url('../fonts/pretendard/pretendard-regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family: 'pretendard';
    src: url('../fonts/pretendard/pretendard-bold.eot');
    src: url('../fonts/pretendard/pretendard-bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/pretendard/pretendard-bold.woff2') format('woff2'),
        url('../fonts/pretendard/pretendard-bold.woff') format('woff'),
        url('../fonts/pretendard/pretendard-bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
}

