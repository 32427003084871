/*
|--------------------
|      NEWS
|--------------------
*/


.filters {
    display: flex;
    align-items: center;

    .filter-container {
        display: flex;
        align-items: center;

        & + .filter-container {
            margin: {
                left: 80px;
            }
        }

        .filter-title {
            white-space: nowrap;

            margin: {
                right: 40px;
            }
        }

        .filter-item {
            color: $dark-grey;
            font-weight: 600;
            text-transform: uppercase;

            margin: {
                right: 40px;
            }

            cursor: pointer;

            &.active {
                color: $blue;

                border: {
                    bottom: 2px solid $blue;
                }
            }
        }

        .filter-sort {
            width: 120px;
            margin: 0;
        }

    }
}


.load-more-container {

    min-height: 100px;

    display: flex;
    align-items: center;
    justify-content: center;

    .loader-container {
        display: none;
    }
}

