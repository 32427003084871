#template-recrutements{
    .content-hero{
        .assets-container{
            display: none;
        }
    }
}

.subtitle-job{

    li{
        position: relative;
        display: flex;
        align-items: center;

        &:not(:first-child){
            margin-left: 12px;
            padding-left: 12px;

            &:before{
                content: '';
                position: absolute;
                left: 0;
                background-color: $blue;
                height: 4px;
                width: 4px;
            }
        }
    }
}

#anchor-container{
    @include media-breakpoint-down(md) {
        display: none;
    }

    a{
        position: relative;
        transition: all .4s ease;

        &:before{
            content: '';
            position: absolute;
            height: 1px;
            width: 100%;
            background: $blue;
            bottom: 0;
            left: 0;
            transform: scaleX(0);
            transition: transform .4s ease;
        }
        
        &:hover, &.is-active{
            color: $blue !important;

            &:before{
                transform: scaleX(1);
            }
        }
    }
}