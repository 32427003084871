/*
|--------------------
|     PAGE HOME
|--------------------
*/
#sector-section {
    .assets-container {
        &:first-child {
            top: 0;
            left: 0;
            margin-top: -140px;
            transform: rotate(165deg);
            margin-left: -120px;
        }

        &:nth-child(2) {
            bottom: 0;
            left: 50%;
            margin-bottom: -340px;
            transform: rotate(260deg);
            margin-left: 40px;
            z-index: 10;
        }
    }

    .sector-swiper {
        .swiper-wrapper {
            align-items: center;
        }

        a {
            display: flex;
            width: 80%;
            border: 1px solid $white;
            transition: all 0.4s ease;

            .icon-sector {
                height: 20px;
                width: 20px;

                svg {
                    height: 100%;
                    width: auto;
                }
            }

            &:hover {
                background: $white;
                color: $blue;

                .icon-sector {
                    svg {
                        g,
                        path,
                        polygon,
                        rect {
                            stroke: $blue;
                        }
                    }
                }
                .svg-white {
                    &.svg-stroke {
                        svg {
                            path,
                            g,
                            polyline {
                                stroke: $blue;
                            }
                        }
                    }
                }
            }
        }
    }

    .content-container {
        position: relative;
    }

    .swiper-pagination-progressbar {
        position: relative;

        width: 80%;
        height: 1px;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 1px;
            opacity: 0.4;
            background: $white;
        }

        .swiper-pagination-progressbar-fill {
            background: $white;
            height: 2px;
        }

        @include media-breakpoint-down(sm) {
            width: 70%;
        }
    }

    .indicator-scroll {
        display: flex;
        justify-content: space-between;
        width: 12%;
        padding: 0 20px 0 0;

        .swiper-button-prev{
            svg{
                transform: rotate(180deg);
            }
        }   

        .swiper-button-prev,
        .swiper-button-next{
            height: 12px;
            position: relative;

            &:after{
                display: none;
            }
        }
        // text-align: right;

        @include media-breakpoint-down(sm) {
            width: 30%;
        }
    }
}

.drop-container {
    position: relative;
    border-bottom: 1px solid $grey;

    a {
        width: 100%;
    }

    .hidden-content {
        overflow: hidden;

        .expertise-img {
            position: absolute;
            top: 50%;
            right: 50%;
            margin-top: -100px;
            margin-right: -30%;
            height: 200px;
            width: 160px;
            opacity: 0;
            transition: opacity 1.2s ease;

            .bg-border {
                clip-path: polygon(50% 0%, 98% 23%, 93% 100%, 24% 91%, 0 14%);
            }

            .bg-container {
                height: 100%;
                width: 100%;
                clip-path: polygon(50% 0%, 98% 23%, 93% 100%, 24% 91%, 0 14%);
            }

            @include media-breakpoint-down(sm) {
                display: none;
            }
        }

        &.is-active {
            .expertise-img {
                opacity: 1;
            }
        }
    }

    &.is-active {
        h2 {
            font-weight: 600;
        }
    }
}

.img-mask {
    position: relative;
    .bg-container,
    .bg-border {
        clip-path: polygon(50% 0%, 98% 23%, 93% 100%, 24% 91%, 0 14%);
    }
}

.img-mask-reverse {
    position: relative;
    .bg-container,
    .bg-border {
        clip-path: polygon(38% 0, 100% 14%, 77% 88%, 7% 100%, 1% 16%);
    }
}

.bg-mask {
    clip-path: polygon(0 9%, 36% 0, 100% 8%, 100% 86%, 65% 100%, 0 96%);
}

#split-section {
    .img-mask,
    .img-mask-reverse {
        height: 540px;
        width: 100%;

        @include media-breakpoint-down(sm) {
            height: 460px;
        }
    }

    &.left-split {
        .assets-container {
            margin-top: 70px;
            margin-left: -210px;
            transform: rotate(195deg);
        }

        .img-mask,
        .img-mask-reverse {
            float: right;
        }
    }

    &.right-split {
        .assets-container {
            margin-top: 70px;
            margin-left: -210px;
            transform: rotate(195deg);
        }

        .img-mask,
        .img-mask-reverse {
            float: left;
        }
    }

    &.bg-blue {
        .content-container {
            .cms {
                ul {
                    li {
                        color: inherit;

                        &:before {
                            background-color: $white;
                        }
                    }
                }
            }
        }
    }
}

#news-section {
    .assets-container {
        top: 50%;
        left: 0;
        width: 480px;
        height: 480px;

        margin-top: -20px;
        transform: rotate(180deg);
        margin-left: -160px;
    }
}

#contact-section {
    .assets-container {
        &:first-child {
            height: 320px;
            width: 320px;
            bottom: 0;
            left: 0;
            margin-bottom: -140px;
            transform: rotate(95deg);
            margin-left: -80px;
            z-index: 10;
        }

        &:nth-child(2) {
            height: 480px;
            width: 480px;
            top: 0%;
            right: 0%;
            margin-top: 40px;
            transform: rotate(120deg);
            margin-right: -140px;
            z-index: 10;
        }

        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    .img-mask {
        height: 540px;
        width: 100%;

        .bg-border {
        }
    }

    .img-mask-reverse {
        position: absolute;
        height: 280px;
        width: 50%;
        bottom: 0;
        margin-bottom: -40px;
        left: 0;
        margin-left: -40px;
    }
}
