/*
|--------------------
|      CONTENT
|--------------------
*/
.py-xl {
    @include media-breakpoint-down(lg) {
        padding-top: 40px !important;
        padding-bottom: 40px !important;
    }
}

.pt-xl {
    @include media-breakpoint-down(lg) {
        padding-top: 40px !important;
    }
}

.pb-xl {
    @include media-breakpoint-down(lg) {
        padding-bottom: 40px !important;
    }
}

.py-xxl {
    @include media-breakpoint-down(lg) {
        padding-top: 60px !important;
        padding-bottom: 60px !important;
    }
}

.pt-xxl {
    @include media-breakpoint-down(lg) {
        padding-top: 60px !important;
    }
}

.pb-xxl {
    @include media-breakpoint-down(lg) {
        padding-bottom: 60px !important;
    }
}

.mb-md {
    @include media-breakpoint-down(md) {
        margin-bottom: 16px !important;
    }
}

.c-blue {
    svg {
        margin-right: 12px;
        polyline {
            stroke: $blue;
        }
    }
}

.arrow-reverse {
    svg {
        transform: rotate(180deg);
    }
}

.z-up {
    z-index: 10;
}

.cc-2 {
    column-count: 2;
    column-gap: 80px;

    @include media-breakpoint-down(md) {
        column-count: 1;
    }
}

.svg-light-blue {
    svg {
        g,
        path,
        polygon {
            fill: $light-grey;
        }
    }
}

.svg-blue {
    &.svg-stroke {
        svg {
            path,
            g,
            polyline {
                stroke: $blue;
            }
        }
    }
}

.svg-white {
    &.svg-stroke {
        svg {
            path,
            g,
            polyline {
                stroke: $white;
            }
        }
    }
}

section {
    position: relative;
}

.subtitle {
    text-transform: uppercase;
    letter-spacing: 0.24em;
    font-size: 12px;
    color: $blue;
}

.bg-light-grey {
    margin-top: -20px;

    &:not(.no-bg-up) {
        padding-top: 40px;
        margin-top: 60px;

        @include media-breakpoint-down(sm) {
            margin-bottom: 20px;
        }
    }

    &:not(.no-bg-down) {
        padding-bottom: 0px;
        margin-bottom: 200px;

        @include media-breakpoint-down(sm) {
            margin-bottom: 60px;
        }
    }

    &:not(.banner):not(.no-padding) {
        &.no-bg-up {
            padding-top: 60px;
        }
        &.no-bg-down {
            padding-bottom: 60px;
        }
    }

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        margin-top: -58px;
        height: 60px;

        background-image: url("../img/utils/bg-light-up.svg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;

        @include media-breakpoint-down(sm) {
            margin-top: -18px;
            height: 20px;
        }
    }

    &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        margin-bottom: -136px;
        height: 138px;

        background-image: url("../img/utils/bg-light-down.svg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;

        @include media-breakpoint-down(sm) {
            margin-bottom: -50px;
            height: 60px;
        }
    }
}

.bg-blue {
    color: $white;
    margin-top: -20px;

    &:not(.no-bg-up) {
        padding-top: 40px;
        margin-top: 60px;

        @include media-breakpoint-down(sm) {
            margin-top: calc(60px + 60px);
        }
    }

    &:not(.no-bg-down) {
        padding-bottom: 0px;
        margin-bottom: calc(200px + 60px);

        @include media-breakpoint-down(sm) {
            margin-bottom: calc(20px + 60px);
        }
    }

    &:not(.banner):not(.no-padding) {
        &.no-bg-up {
            padding-top: 60px;
        }
        &.no-bg-down {
            padding-bottom: 60px;
        }
    }

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        margin-top: -58px;
        height: 60px;

        background-image: url("../img/utils/bg-blue-up.svg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;

        @include media-breakpoint-down(sm) {
            margin-top: -18px;
            height: 20px;
        }
    }

    &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        margin-bottom: -136px;
        height: 138px;

        background-image: url("../img/utils/bg-blue-down.svg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;

        @include media-breakpoint-down(sm) {
            margin-bottom: -50px;
            height: 60px;
        }
    }

    .title-container {
        svg {
            g {
                stroke: $white;
            }
        }
    }

    .subtitle {
        color: $white;
    }
}

.no-bg-up {
    &:before {
        display: none;
    }
}

.no-bg-down {
    &:after {
        display: none;
    }
}

#page-home {
    h1 {
        color: $black;
    }
}

.logo-img {
    height: 60px;
    width: auto;
}

.title-container {
    display: flex;
    flex-direction: column;

    &.ta-c {
        align-items: center;
        width: 100%;
    }
}

.assets-container {
    position: absolute;

    width: 400px;
    height: 400px;

    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;

    &.is-white {
        background-image: url("../img/icon/asset-icon-white.svg");
    }

    &:not(.is-white) {
        background-image: url("../img/icon/asset-icon.svg");
    }

    @include media-breakpoint-down(md) {
        opacity: 0;
    }
}

.bg-border {
    position: absolute;
    top: 0;
    left: 0;
    margin-top: -2px;
    margin-left: -2px;
    background: $blue;
    z-index: -1;
    height: calc(100% + 4px);
    width: calc(100% + 4px);
}

.bg-blue {
    .bg-border {
        background: $white;
    }
}
/*
|
| Banner
|---------
*/
.banner {
    position: relative;
    z-index: 2;
    width: 100%;
    min-height: 50vh;

    .banner-container {
        display: flex;
        align-items: center;
        width: 100%;
        min-height: 50vh;

        .bg-hero {
            position: relative;
            width: 45%;
            min-height: 50vh;
            margin-top: 12px;

            .bg-masked {
                width: 100%;
                clip-path: polygon(0 0, 100% 0, 65% 87%, 0% 100%);
            }

            .bg-border {
                height: calc(100% + 5px);
                width: calc(100% + 5px);
                clip-path: polygon(0 0, 100% 0, 65% 87%, 0% 100%);
            }

            .bg-container {
                position: absolute;
                min-height: 50vh;
            }
        }

        .content-hero {
            padding-left: 2%;
            padding-right: 2%;
            width: 55%;
        }

        @include media-breakpoint-down(sm) {
            display: block;

            .bg-hero {
                margin-bottom: 40px;
                width: 100%;
            }

            .content-hero {
                width: 100%;
            }
        }
    }

    .assets-container {
        pointer-events: none;
        right: 0;
        bottom: 0;
        margin-right: -120px;
        margin-bottom: -60px;
    }

    &.is-full {
        height: 90vh;

        .banner-container {
            height: 90vh;

            .bg-hero {
                height: 90vh;

                .bg-container {
                    height: 90vh;
                }
            }
        }

        @include media-breakpoint-down(sm) {
            height: 100%;

            .banner-container {
                height: 100%;

                .bg-hero {
                    margin-bottom: 40px;
                    height: 40vh;

                    .bg-container {
                        height: 40vh;
                    }
                }
            }
        }
    }
}

/*
|
| Custom card
|
| Use in news archive page
|--------------
*/
.custom-card {
    .custom-card-link {
        display: block;
    }

    h2 {
        transition: all 0.24s ease;
    }

    .arrow-right {
        position: absolute;
        bottom: 0;
        left: 50%;
        margin-left: -30px;
        margin-bottom: -20px;
        background-image: url("../img/icon/arrow-bg.svg");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center;
        height: 80px;
        width: 60px;
        z-index: 10;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            margin-left: 10px;
        }
    }

    /*
    * Card img
    */
    .card-img-container {
        position: relative;
        overflow: hidden;
        height: 240px;
        background-color: $black;

        &:after {
            content: "";
            display: block;
            padding-bottom: 100%;
        }

        .card-img {
            position: absolute;

            top: 0;
            left: 0;

            width: 100%;
            height: 100%;

            transition: transform 2s $easeOutExpo;
            will-change: transform;
        }
    }

    /*
    * Card content
    */
    .card-content {
        .date {
            color: rgba($very-dark-grey, 0.6);
        }
    }

    /*
    * Hover state
    */
    &:hover {
        h2 {
            font-weight: 600;
            color: $blue;
        }

        .arrow-right {
            background-image: url("../img/icon/arrow-bg-blue.svg");

            svg {
                path,
                g,
                polyline {
                    stroke: $white;
                }
            }
        }

        .card-img-container {
            .card-img {
                transform: scale(1.05);
            }
        }
    }
}

/*
|
| Pagination
|---------------
*/
.pagination-container {
    li {
        display: inline-block;

        a {
            transition: all 0.3s ease-out;
            padding: 0 8px;

            &:hover {
                color: $grey;
            }
        }

        .current {
            padding: 0 8px;
            font-weight: 800;
            color: $grey;
        }
    }
}

/*
|
| CMS
|------
*/
.cms {
    span {
        display: inline;
    }

    ul {
        li {
            position: relative;

            padding-left: 32px;

            margin-bottom: 12px;

            color: $dark-grey;

            &:before {
                content: "";

                position: absolute;
                top: 7px;
                left: 0;

                width: 12px;
                height: 12px;

                background: $blue;
            }

            ul {
                li {
                    &:before {
                        content: "";

                        position: absolute;
                        top: 9px;
                        left: 0;

                        width: 8px;
                        height: 8px;

                        background: $blue;
                    }
                }
            }
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-bottom: 30px;
    }

    h1 {
        @extend .title-xxl;
    }
    h2 {
        @extend .title-xl;
    }
    h3 {
        @extend .title-lg;
    }
    h4 {
        @extend .title-md;
    }
    h5 {
        @extend .title-sm;
    }
    h6 {
        @extend .title-xs;
    }

    img {
        display: block;
        width: 100%;
        height: auto;
    }

    strong {
        font-weight: 800;
    }

    p {
        color: $dark-grey;
        margin-bottom: 20px;
    }

    &.cms-no-break {
        p {
            margin-bottom: 0;
        }
    }

    blockquote {
        position: relative;

        width: 80%;

        margin-right: 0;
        margin-left: auto;
        padding: 130px 0;

        font-size: 30px;
        line-height: 1.5em;
        font-weight: 300;

        @include media-breakpoint-down(md) {
            margin: auto;
        }

        &:before {
            content: "";

            position: absolute;

            background-image: url("../img/icon/quote-left.svg");

            width: 50px;
            height: 50px;

            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;

            transform: translateX(-60px) translateY(-30px);
        }

        &:after {
            content: "";

            position: absolute;
            right: 0;

            background-image: url("../img/icon/quote-right.svg");

            width: 50px;
            height: 50px;

            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;

            transform: translateX(0) translateY(-50px);

            @include media-breakpoint-down(md) {
                transform: translateX(60px) translateY(-50px);
            }
        }
    }

    .wp-video {
        margin: 20px 0;
    }
}

.bg-blue {
    .cms {
        p {
            color: inherit;
        }
    }
}
/*
|
| Page offset
|--------------
*/
.page-offset {
    padding-top: $headerHeight;

    @include media-breakpoint-down(lg) {
        padding-top: $headerHeightSm;
    }
}

/*
|
| Custom Loader
|---------------
*/
.custom-loader {
    width: 100px;
    display: inline-block;

    &.cl-center {
        margin-left: auto;
        margin-right: auto;
    }

    svg {
        display: inline-block;
        width: 100%;
        height: auto;
        fill: $black;

        circle {
            stroke: $black;
        }
    }

    &.ajax-loader {
        display: none;
    }
}

#cursor-container {
    position: absolute;
    width: auto;
    height: auto;
    top: 50%;
    left: 50%;
    margin: -25px 0 0 -25px;
    padding: 4vw;
    pointer-events: none;
    z-index: 2000;
    -webkit-backface-visibility: hidden;

    &:before {
        content: "";
        position: absolute;
        height: 50px;
        width: 50px;
        top: 0;
        left: 0;
        border: 1px solid $blue;
        border-radius: 50%;
        pointer-events: none;
        transition: opacity 0.4s ease 0.4s, transform 1s $easeSmooth;
    }

    @include media-breakpoint-down(sm) {
        display: none;
    }
}

.index-video {
    height: auto;
    width: 100%;
    max-width: 1000px;
    z-index: -100;
    right: 0;
    bottom: 0;
    background: no-repeat center center;
    background-size: cover;
    overflow: hidden;
}
#page-sample #video-section .video-mask {
    height: initial !important;
}

.yt-video {
    height: 565px !important;
    max-width: 1000px;
    position: relative !important;
    right: 0;
    bottom: 0;
    background: no-repeat center center;
    background-size: cover;
    overflow: hidden;
}
