/*
|--------------------
|       Contact
|--------------------
*/

#page-contact {
    .icon-item {
        svg {
            height: 20px;
            width: auto;
        }

        &.mail-item {
            svg {
                height: 14px;
                width: auto;
            }
        }
    }
    .section-map {
        #map {
            position: relative;
            width: 100%;
            height: 50vh;
            background-color: $black;
        }
    }

    &.page-candidature {
        h1 {
            color: $white;
        }

        input:not([type="checkbox"]),
        textarea,
        select {
            border-bottom: 1px solid $white;
        }

        label {
            color: $white;
        }

        .gfield_required {
            &:before {
                color: $white;
            }
        }

        select {
            background-image: url("../img/icon/bottom-white.svg");
        }

        .materialize-form {
            input[type="submit"] {
                background-color: $white;
                color: $blue;
            }

            select {
                color: $white;
                background-color: $blue;
            }

            .gform_confirmation_message {
                color: $white;
            }
        }
    }
}

.form-blue {
    input:not([type="checkbox"]),
    textarea,
    select {
        border-bottom: 1px solid $white;
    }

    label {
        color: $white;
    }

    .gfield_required {
        &:before {
            color: $white;
        }
    }

    select {
        background-image: url("../img/icon/bottom-white.svg");
    }

    .materialize-form {
        input[type="submit"] {
            background-color: $white;
            color: $blue;
        }

        select {
            color: $white;
            background-color: $blue;
        }

        .gform_confirmation_message {
            color: $white;
        }
    }

    .ginput_container_fileupload {
        overflow: hidden;

        input[type="file"] {
            width: calc(100% + 135px) !important;
            max-width: calc(100% + 135px) !important;

            margin: {
                left: -135px;
            }
        }
    }
}

.gfield_consent_label {
    a {
        text-decoration: underline;
    }
}

.gform_wrapper.gravity-theme {
    .gfield_description {
        width: auto !important;
    }
}

.page-template-contact,
.page-template-candidater,
.error404,
.single-post,
.single-recrutements {
    #contact-section {
        display: none;
    }
}
