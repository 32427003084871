/*
|--------------------------
|      DEFAULT & RESET
|--------------------------
*/
html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
  overflow-x: hidden;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  font-family: $font-family-default;
  color: $default-color;
  font-size: $default-font-size;
  line-height: $default-line-height;
  font-weight: 400;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  overflow-x: hidden;

  &.blog, .single-recrutements{
    background-color: $light-grey;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @each $property, $value in $default-titles {
    #{$property}: $value;
  }

  font-weight: 400;

  strong {
    color: $blue;
    font-weight: 700;
  }
}

h1 {
  font-weight: 200;
  color: $blue;
}

button,
input,
textarea {
  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}

button {
  -webkit-appearance: none;
  padding: 0;
  background-color: transparent;
  border: none;
}

img {
  max-width: inherit;
}

a,
img,
span,
button {
  display: inline-block;
}

a {
  color: inherit;
  text-decoration: none;
}

ul {
  margin: 0;
  padding: 0;

  li {
    list-style-type: none;
  }
}

.title-bold{
  h1{
    font-weight: 600;
  }
}
