/*
|--------------------
|      BUTTONS
|--------------------
*/
a{

    &.primary{
        background-image: url('../img/utils/primary-btn.svg');
        background-repeat: no-repeat;
        background-size: contain;
        height: 90px;
        width: 340px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $white;
        text-transform: uppercase;
        letter-spacing: .24em;
        font-size: 12px;
        font-weight: 700;

        &:hover{
            color: $blue;
            background-image: url('../img/utils/primary-btn-white.svg');
        }
    }
}

.bg-blue{

    a{

        &.primary{
            background-image: url('../img/utils/primary-btn-white.svg');
            color: $blue;

            &:hover{
                color: $white;
                background-image: url('../img/utils/primary-btn.svg');
            }
        }
    }
}