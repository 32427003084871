/*
|--------------------
|      SINGLE
|--------------------
*/
.single-post {
    .banner {
        &.bg-light-grey {
            margin-top: 0px;
        }

        .banner-container {
            align-items: flex-start;

            .bg-hero {
                margin-top: 4px;
            }
        }
    }

    #partner-section{
       padding-bottom: 120px !important;
    }

    #description-blue-section{
        &:not(.no-bg-up) {
            padding-top: 40px;
            margin-top: 0px !important;
        }
    }

    .link-hero{
        a{
            svg{
                height: 16px;
                width: auto;
            }
        }
    }
}
